import '../scss/style.scss';

svg4everybody();
objectFitImages();

// Price range slider
if (window.noUiSlider) {
    var priceSlider = document.getElementById('rangeSlider');
    var inputs = [
        document.getElementById('minPrice'),
        document.getElementById('maxPrice')
    ];

    noUiSlider.create(priceSlider, {
        start: [inputs[0].value, inputs[1].value],
        connect: true,
        range: {
            'min': parseInt(minPrice),
            'max': parseInt(maxPrice)
        }
    });
    priceSlider.noUiSlider.on('update', function (values, handle) {
        inputs[handle].value = Math.round(values[handle]);
    });

    inputs.forEach(function (input, handle) {

        input.addEventListener('change', function () {
            priceSlider.noUiSlider.setHandle(handle, this.value);
        });

    });
}

(function ($) {
    $(document).ready(function(){
        var eventhandler = function(e) {
            e.preventDefault();
        };

        // Tabs
        $('.tabContent').hide();
        $('.tabContent.tabShow').show();
        $('.tabNav>a').click(function(e) {
            $('.tabNav>a').removeClass('active');
            $('.tabContent').removeClass('tabShow').hide();
            $(this).addClass('active');
            $($(this).attr("href")).addClass('tabShow').fadeIn(700);

            e.preventDefault();
        });

        // Drop-down
        $('.catalogButton').on('click', function() {
            $(this).next('ul').slideToggle(500);
            $(this).toggleClass('active');
        });
        // Header menu
        $('.submenu').children('a').on('click', eventhandler);
        $('.submenu').on('click', function() {
            $('.submenu').not(this).children('ul').slideUp(500);
            $('.submenu').not(this).children('a').removeClass('activeDropdown');
            $(this).children('a').toggleClass('activeDropdown');
            $(this).children('ul').slideToggle(500);
        });

        // Mobile menu
        $('#mobile-menu').on('click', function() {
            $(this).toggleClass('menuOpen');
            $('body').toggleClass('no-scroll');
            $('.menuMob').slideToggle(500);
        });

        // Language
        $('.selectLang').on('click', function() {
            $(this).toggleClass('activeDropdown');
            $('.menuLang').slideToggle(500);
        });

        // Filter
        $('#filter-on').on('click', function () {
            $('body').addClass("overlay");
            $('.filterWrapper').toggle({ direction: "right" }, 1000);
            $('.filterOn').slideUp(500);
        });
        $('#filter-off').on('click', function () {
            $('.filterWrapper').toggle({ direction: "left" }, 1000);
            $('.filterOn').slideDown(500);
            $('body').removeClass("overlay");
        });

        // Hide drop-down
        $(document).on('click', function(event) {
            var $clicked = $(event.target);

            if (! $clicked.parents().hasClass("catalogNavigation")) {
                $('.catalogButton').removeClass('active');
                $('.catalogButton').next('ul').slideUp(500);
            }
            if (! $clicked.parents().hasClass("formSearch")) {
                $('.iconSearch').removeClass('active');
                $('.searchWrapper').slideUp(500);
            }
            if (! $clicked.parents().hasClass("lang")) {
                $('.selectLang').removeClass('activeDropdown');
                $('.menuLang').slideUp(500);
            }
            if (! $clicked.parents().hasClass("submenu")) {
                $('.submenu>a').removeClass('activeDropdown');
                $('.submenu>ul').slideUp(500);
            }
        });

        // Pop-up
        $('#order').on('click', function () {
            $('body').addClass("overlay");
            $('.popup').fadeIn(500);
        });
        $('#popup-close').on('click', function () {
            $('.popup').fadeOut(500);
            $('body').removeClass("overlay");
        });

        // Scrolling tables
        const scrollTable = $('.content');

        if (scrollTable.length > 0 && scrollTable.find('table').length > 0) {
            $('table').wrap("<div class='scrollTable'></div>");
        };

        //Spinner
        $.fn.spinner = function() {
            this.each(function() {
                var el = $(this);
                // add elements
                el.wrap('<span class="spinner"></span>');
                el.before('<span class="sub">-</span>');
                el.after('<span class="add">+</span>');

                // substract
                el.parent().on('click', '.sub', function () {
                    if (el.val() > parseInt(el.attr('min')))
                        el.val( function(i, oldval) { return --oldval; });
                });

                // increment
                el.parent().on('click', '.add', function () {
                    if (el.val() < parseInt(el.attr('max')))
                        el.val( function(i, oldval) { return ++oldval; });
                });
            });
        };
        $('.orderProductAmount>input[type=number]').spinner();

        //Fancybox
        if ($.fn.fancybox) {
            $('[data-fancybox="gallery"]').fancybox({
                // Options will go here
            });
        }

        // Slick
        if ($.fn.slick) {
            $('.slideHome').slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
                speed: 700,
                arrows: true,
                fade: true
            });

            $('.carouselNews').slick({
                dots: true,
                arrows: false,
                infinite: false,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 959,
                        settings: {
                            slidesToShow: 2,
                            arrows: true,
                            dots: false,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 639,
                        settings: {
                            slidesToShow: 1,
                            arrows: true,
                            dots: false,
                            infinite: true
                        }
                    }
                ]
            });

            $('.carouselPartners').slick({
                dots: false,
                arrows: true,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 959,
                        settings: {
                            slidesToShow: 3,
                            arrows: true,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 639,
                        settings: {
                            slidesToShow: 2,
                            arrows: true,
                            infinite: true
                        }
                    },
                    {
                        breakpoint: 360,
                        settings: {
                            slidesToShow: 1,
                            arrows: true,
                            infinite: true
                        }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });

            // Product photo

            $('.productGallery').slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                speed: 700,
                arrows: false,
                fade: true,
                asNavFor: '.productGalleryNav',
                responsive: [
                    {
                        breakpoint: 479,
                        settings: {
                            arrows: true
                        }
                    }
                ]
            });
            $('.productGalleryNav').slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                asNavFor: '.productGallery',
                arrows: true,
                centerMode: true,
                focusOnSelect: true,
                centerPadding: '2rem',
                responsive: [
                    {
                        breakpoint: 959,
                        settings: {
                            slidesToShow: 2
                        }
                    },
                    {
                        breakpoint: 639,
                        settings: {
                            slidesToShow: 3
                        }
                    }
                ]
            });

        }

        //Sticky top menu
        var toTop = $('.header').offset().top;

        $(window).scroll(function () {
            if ($(window).scrollTop() > toTop) {
                $('.header').addClass("topWindow");
            } else {
                $('.header').removeClass("topWindow");
            }
        });
    });

})(jQuery);